<template>
  <WsMain>
    <WsReadSection
      :id="_id"
      :modelName="modelName"
      :label="label"
      :fields="fields"
      :leftFields="leftFields"
      :rightFields="rightFields"
    ></WsReadSection>
  </WsMain>
</template>

<script>
import model from "@/__vue2stone_cms/models/shop_product";
export default {
  data: () => ({
    modelName: model.modelName,
    label: model.label,
    fields: model.fields,
    leftFields: [
      "name",
      "no",
      "subtitle",
      "order_type",
      "spec",
      "cost",
      "price",
      "discount_price",
      "weight_capacity",
      "weight_capacity_unit",
      "show_weight_capacity",
      "tax",
      "stock_count",
      "stock_alert_count",
      "max_buyable_count",
      "storage_space",
      "purchaser",
      "store_temperature",
      "cover_image",
      "shop_product_cover_frame",
      "images",
      "description",
      "ranking_score",
      "suggests",
      "store_house_class",
      "store_house_subclass",
    ],
    rightFields: [
      "is_active",
      "on_time",
      "off_time",
      "shop_classes",
      "shop_subclasses",
      "featured_classes",
      "areas",
      "area_sections",
    ],
  }),

  computed: {
    _id() {
      return this.$route.params.id;
    },
  },
};
</script>

<style>
</style>